import React, {useEffect, useState} from "react";
import {firestore} from "../firebase/clientApp";
import {Avatar, Box, Button, ButtonGroup, Container, Grid, Typography} from "@mui/material";

import axios from "axios";
import {useParams} from "react-router-dom";
import {useDocument} from "react-firebase-hooks/firestore";

import clap from '../assets/images/clap.png'
import Fountain from "./Fountain";


export default function Buttons({url, idPlaying, eventPlaying, user}) {
    const {id} = useParams()
    const [buttons, setButtons] = useState([])
    const [usersButtons, usersButtonsLoading, usersButtonsError] = useDocument(firestore.doc(url + "/usersButtons/" + user.uid), {});

    useEffect(() => {
        getButtons()
        new Fountain()
    }, [idPlaying, usersButtons])

    function getButtons() {
        firestore.collection(url + '/buttons').orderBy('timestamp', 'asc').onSnapshot(s => {
            let but = []
            s.forEach(b => {
                    but.push({...b.data(), id: b.id})
                }
            )
            setButtons(but)
        })
    }

    function postDataClick(buttonId) {

        axios.post('https://api.secondstage.app/buttons/' + id + '/' + buttonId, {user: user.uid})
            .then(r => {
                navigator.vibrate(200)
            })
            .catch(error => console.log(error))
    }

    function postDataVote(buttonId, value) {
        axios.post('https://api.secondstage.app/buttons/' + id + '/' + buttonId, {value: value, user: user.uid})
            .then(r => {
                navigator.vibrate(200)

            })
            .catch(error => console.log(error))
    }

    const ButtonItem = () => (
        buttons.map((b, i) =>
            <Grid key={i} item xs={12}>
                {(b.type === 'click') ?
                    <Button id='clapButton' disabled={!b.active || !eventPlaying} onClick={() => postDataClick(b.id)} fullWidth
                            variant={(!usersButtonsLoading && usersButtons && usersButtons.data()) ? (usersButtons.data()[b.id] === false ? "contained" : "outlined") : 'outlined'}
                            style={{
                                height: '100px',
                                width:'100%',
                                fontSize: '18px',
                                background: 'linear-gradient(90deg, #00d8a1 0%, #3a4de4)',
                                border:'none',
                                color: '#f1ebe9',
                                borderRadius: '2rem',
                                borderWidth: '3px',
                                //boxShadow:  usersButtons.data()[b.id] === false ? 'rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px' : 'inset 4px 4px 18px 1px #2e2e2e' ,
                                opacity:  (!b.active || !eventPlaying) ? '0.5' : '1'
                            }}
                        startIcon={<img height={'90px'} src={clap} />}
                    >
                        {/*b.title*/}
                    </Button> :
                    <Box sx={{my: 1}}>
                        <Typography textTransform={"uppercase"} style={{fontSize: '18px', fontFamily: 'Gotham Black'}}>
                            Extra Time
                        </Typography>
                        <ButtonGroup disabled={!b.active || !eventPlaying} fullWidth
                                     style={{
                                         height: '80px',
                                     }}>
                            <Button onClick={() => postDataVote(b.id, 'no')}
                                    variant={(!usersButtonsLoading && usersButtons && usersButtons.data()) ? (usersButtons.data()[b.id] === 'no' ? 'outlined' : 'contained') : 'outlined'}
                                    style={{
                                        background: b.background,
                                        border:'none',
                                        borderWidth: '3px',
                                        color: '#f1ebe9',
                                        borderRadius:'1rem 0 0 1rem',
                                        textTransform: 'uppercase',
                                        fontFamily: 'Gotham Black',
                                        opacity:  (!b.active || !eventPlaying) ? '0.5' : '1',
                                        boxShadow:  usersButtons.data()[b.id] === 'no' ? 'inset 4px 4px 18px 1px #2e2e2e' : 'rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px',
                                    }}
                            >
                                <Typography style={{fontSize: '50px'}}>-</Typography>
                            </Button>
                            <Button onClick={() => postDataVote(b.id, 'si')}
                                    variant={(!usersButtonsLoading && usersButtons && usersButtons.data()) ? (usersButtons.data()[b.id] === 'si' ? 'outlined' : 'contained') : 'outlined'}
                                    style={{
                                        background: b.background,
                                        border:'none',
                                        borderWidth: '3px',
                                        color: '#f1ebe9',
                                        borderRadius:'0 1rem 1rem 0',
                                        textTransform: 'uppercase',
                                        fontFamily: 'Gotham Black',
                                        opacity:  (!b.active || !eventPlaying) ? '0.5' : '1',
                                        boxShadow:  usersButtons.data()[b.id] === 'si' ? 'inset 4px 4px 18px 1px #2e2e2e' : 'rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px',
                                    }}
                            >
                                <Typography style={{fontSize: '50px'}}>+</Typography>
                            </Button>
                        </ButtonGroup>
                    </Box>
                }
            </Grid>
        )
    )

    return (
        <Container style={{height:'30%'}}>
            <Grid container sx={{my: 2}}>
                {(usersButtons && usersButtons.data()!==undefined && usersButtons.data()) && <ButtonItem/>}
            </Grid>
        </Container>
    )
}
