import React from "react";
import {AppBar, Toolbar, Typography, useTheme} from "@mui/material";

export default function QuestionBar({tipo,domanda}){
    const theme = useTheme()
    return(
        <AppBar style={{background: tipo ? theme.customColors[tipo].main : ''}} sx={{position:'relative'}}>
            <Toolbar style={{textAlign: 'left'}}>
                <Typography sx={{ml: 2, flex: 1, py:1}} variant="h6" fontWeight='bold' component="div" style={{marginLeft: '16px', fontSize: '1.25rem', lineHeight: '1.5rem', color:'#fff'}}>
                    {domanda}
                </Typography>
            </Toolbar>
        </AppBar>
    )
}
