import {createTheme} from "@mui/material";

const siNo1='#3a4de4'
const siNo2='#00d8a1'
const sentiment1='#3a4de4'
const sentiment2='#00d8a1'
const poll1='#3a4de4'
const poll2='#00d8a1'
const wordcloud1='#3a4de4'
const wordcloud2='#00d8a1'
const quiz1='#3a4de4'
const quiz2='#00d8a1'

const pleshDark = createTheme({

    typography: {
      fontFamily: ['Barlow']
    },
    components:{
        MuiButton:{
            variants: [
                //siNo
                {
                    props: {variant:'siNo'},
                    style:{
                        width: '100%',
                        height: '100%',
                        borderRadius: 10,
                        border: 0,
                        color: "white !important",
                    },
                },
                {
                    props: {variant:'siNo', risposta: 'si'},
                    style:{
                        background: 'linear-gradient('+siNo1+', '+siNo1+')',
                    },
                },
                {
                    props: {variant:'siNo', risposta: 'no'},
                    style:{
                        background: 'linear-gradient('+siNo2+', '+siNo2+')',
                    },
                },
                //sentiment
                {
                    props: {variant:'sentiment'},
                    style:{
                        width: '100%',
                        height: '100%',
                        borderRadius: 10,
                        border: 0,
                        color: "white !important",
                    },
                },
                {
                    props: {variant:'sentiment', risposta: 'daccordo'},
                    style:{
                        background: 'linear-gradient('+sentiment1+', '+sentiment1+')',
                    },
                },
                {
                    props: {variant:'sentiment', risposta: 'nonDaccordo'},
                    style:{
                        background: 'linear-gradient('+sentiment2+', '+sentiment2+')',
                    },
                },
            ]
        },
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#e6fc69',
        },
        secondary: {
            main: '#263238',
        },
        background: {
            default: '#060835',
            paper: '#455a64',
        },
        accent: {
            main: '#e6fc69',
        },
        text: {
            primary: '#eceff1',
        },
    },
    customColors: {
        clickTo: {
            main: '#FFEB3B',
        },
        wordcloud: {
            main: 'linear-gradient(90deg, '+wordcloud1+' 0%, '+wordcloud2+' 100%)'
        },
        quiz: {
            main: 'linear-gradient(90deg, '+quiz1+' 0%, '+quiz2+' 100%)'
        },
        siNo: {
            main: 'linear-gradient(90deg, '+siNo1+' 0%, '+siNo2+' 100%)'
        },
        poll: {
            main: 'linear-gradient(90deg, '+poll1+' 0%, '+poll2+' 100%)'
        },
        sentiment: {
            main: 'linear-gradient(90deg, '+sentiment1+' 0%, '+sentiment2+' 100%)'
        }
    }
});

export default pleshDark
