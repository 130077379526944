import React from "react";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

function Home() {
    return (
        <div>
            Inserisci il codice
        </div>
    )
}

export default Home