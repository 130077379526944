import React, {useEffect, useState} from "react";
import {Button, CircularProgress, FormControl, Grid, Input, styled, TextField, Typography} from "@mui/material";
import ReactWordcloud from "react-wordcloud";
import {firebase} from "../../firebase/clientApp";
import {useParams} from "react-router-dom";
import arrow from '../../assets/arrow.svg'
import wordCheck from "../../functions/wordCheck";
import SuccessCheck from "../SuccessCheck";
import {useMotionValue, motion} from "framer-motion";


const options = {
    colors: ['#f1ebe9'],
    enableTooltip: false,
    deterministic: true,
    fontFamily: "roboto",
    fontSizes: [16, 40],
    fontStyle: "normal",
    fontWeight: "bold",
    padding: 0,
    rotations: 3,
    rotationAngles: [0],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 1000
};

const CustomTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#0288D1',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#0288D1',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#f1ebe9',
        },
        '&:hover fieldset': {
            borderColor: '#f1ebe9',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#0288D1',
        },
    },
});

export default function WordcloudQuestion({tipo,sendAnswer, success, answered, sending,elementId}){
    const {id} = useParams()
    const [word, setWord] = useState('')
    const [words, setWords] = useState([])
    const [spazio, setSpazio] = useState(false)
    const [display, setDisplay] = useState(true)
    let progress = useMotionValue(90)

    useEffect(()=>{
        tipo==='wordcloud' && getWcData()
    },[tipo])

    function getWcData (){
        firebase.database().ref('users/plesh/events/' + id + '/queue/'+elementId).on('value', (s) => {
            let res = s.val();
            const bv = []
            Object.entries(res.answers).forEach((doc) => {
                const [key, value] = doc;
                bv.push({text: key, value: value})
            })
            setWords(bv);
        })
    }

    const handleSetWord = (event) => {
      setWord(event.target.value)
    }

    function capitalizeTheFirstLetterOfEachWord(words) {
        var separateWord = words.toLowerCase().split(' ');
        for (var i = 0; i < separateWord.length; i++) {
            separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
                separateWord[i].substring(1);
        }
        return separateWord.join(' ');
    }

    const handleSendAnswer = () => {
        let w = capitalizeTheFirstLetterOfEachWord(word)
        setSpazio(false)
        if(w.includes(' ')){
            setSpazio(true)
        } else {
            if(wordCheck(w)){
                sendAnswer(w)
                setWord('')
            } else {
                setWord('')
            }
        }

    }

    return(
        <Grid direction={'column'} sx={{position:'relative'}} container justifyContent={"space-evenly"} alignItems={"center"} style={{textAlign:'center', height:'100%'}} spacing={5}>
            {/*display && <Grid item style={{width: '80%', maxHeight: '400px'}}>
                <ReactWordcloud words={words} options={options}/>
            </Grid>*/}
            {(!success) && <Grid item style={{width: '80%'}}>
                <FormControl variant="standard" style={{width: '100%'}}>
                    <CustomTextField /*onFocus={() => setDisplay(false)} onBlur={() => setDisplay(true)}*/
                        placeholder="Inserisci una parola" id="component-simple" value={word} onChange={handleSetWord}
                        variant="standard"/>
                </FormControl>
                {spazio &&
                    <Typography sx={{mt: 1}}>Puoi inviare solo una parola</Typography>
                }
                <Button sx={{mt: 4}}
                        style={{
                            /*background: 'linear-gradient(90deg, rgba(234,77,149,1) 0%, rgba(231,110,81,1) 100%)',*/
                            background: 'linear-gradient(90deg, #3a4de4, #00d8a1)',
                            color: '#f1ebe9', width: '100%', height: '50px'
                        }}
                        onClick={handleSendAnswer}>Invia</Button>
            </Grid>}
            {(success || answered) &&
                <Grid item style={{width:'80%'}}>

                    <motion.div
                        initial={{ x: 0 }}
                        animate={{ x: 100 }}
                        style={{ x: progress }}
                        transition={{ duration: 0.8 }}
                    />
                    <SuccessCheck progress={progress}/>
                </Grid>
            }
        </Grid>
    )
}
