import React from "react";
import {Box, Typography} from "@mui/material";

const ag = [
    {
        ora:'9.50 – Welcome',
        contenuto:'Enrico Galletti e Giusi Legrenzi - RTL 102.5'
    },
    {
        ora:'10.00 – Confìndustria per i giovani',
        contenuto:'Giovanni Brugnoli - Vice Presidente per il Capitale Umano Confindustria'
    },
    {
        ora:'10.20 - Talento e carattere nell’arte',
        contenuto:'Jago - Artista'
    },
    {
        ora:'10.40 – La scuola fucina di talenti, palestra di carattere',
        contenuto:'Patrizio Bianchi - Ministro dell’Istruzione'
    },
    {
        ora:'10.50 – La riforma dell’orientamento',
        contenuto:'Ne parlano con il Ministro Bianchi i rappresentanti\n' +
            'di studenti, docenti, presidi, imprese, ITS, università.\n' +
            '<br/>Moderano Enrico Galletti e Giusi Legrenzi'
    },
    {
        ora:'11.30 – Talento e carattere nello sport e nello studio: parola ai Luiss top athletes',
        contenuto:'Federica Cesarini - Olimpionica di canottaggio medaglia d’oro Tokyo 2020.<br/>Giorgio Avola - Olimpionico di scherma medaglia d’oro Londra 2012'
    },
    {
        ora:'12.00 – Conclude Giovanni Brugnoli',
        contenuto:'Vice Presidente per il Capitale Umano Confindustria'
    }
]


const Agenda = () => {
  return(
      <Box style={{maxHeight:'57vh',height:'50%', overflow:'scroll'}}>
          <Box sx={{m:2}} style={{overflow:'scroll', height:'100%'}}>
              {ag.map(a =>
                  <Box sx={{textAlign:'left'}} style={{overflow:"visible"}}>
                      <Typography component={'div'} variant={"subtitle1"} fontWeight={"bold"} textTransform={"uppercase"} style={{color:'#26DEA8'}}>
                          {a.ora}
                      </Typography>
                      <Typography dangerouslySetInnerHTML={{__html: a.contenuto}}>

                      </Typography>
                  </Box>
              )}
          </Box>
      </Box>

  )
}

export default Agenda
