import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import {firebase} from "../firebase/clientApp";
import 'firebase/compat/auth';
import logo from '../assets/images/logo-2nd.png'
import {Stack} from "@mui/material";
import conf from '../assets/images/evento/marchio.png'

// Configure FirebaseUI.
const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'redirect',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    //signInSuccessUrl: '/vote/4X8HOhhhNgrW4nJC9Kga',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        {
            provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            scopes:['public_profile', 'email']
        }
    ],
};

function SignInScreen() {
    return (
        <div>
            {/*<h1>Benvenuto in</h1>*/}
            <img style={{width:'90%', maxWidth:'400px', marginTop:'2rem'}} src={conf}/>
            <p>Esegui il login:</p>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
            <Stack direction={"row"} justifyContent={'space-evenly'} sx={{my: {xs: 10, md:0}, bottom:0}} alignItems={"center"}>
                <a href={'https://2ndstage.app/privacy-policy'} target={'_blank'} style={{color:'#fff', textDecoration:'none',opacity:0.4, fontFamily:'Forever Grotesk', fontWeight:400, fontSize:'1.313rem'}}>Cookies</a>
                <a href={'https://2ndstage.app/privacy-policy'} target={'_blank'} style={{color:'#fff', textDecoration:'none',opacity:0.4, fontFamily:'Forever Grotesk', fontWeight:400, fontSize:'1.313rem'}}>Privacy</a>
            </Stack>
        </div>
    );
}

export default SignInScreen;
