import React, {useState} from "react";
import {Box, Button, CircularProgress, Stack, TextField, Typography} from "@mui/material";
import {withStyles} from "@material-ui/core/styles";
import {motion, useMotionValue} from "framer-motion";
import SuccessCheck from "./SuccessCheck";
import axios from "axios";

const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'rgba(231,110,81,1)',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'rgba(231,110,81,1)',
            },
        },
    },
})(TextField);

const ApplyForm = ({user}) => {
    //const [email, setEmail] = useState('')
    const [sending, setSending] = useState(false)
    const [sent, setSent] = useState(false)
    const [error, setError] = useState(false)
    const [invalidMail, setinvalidMail] = useState(false)
    let progress = useMotionValue(90)

    /*const handleSetEmail = (e) => {
        setEmail(e.target.value)
    }*/

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const inviaMail = () => {

        setSending(true)
        setinvalidMail(false)
        setError(false)
        if (validateEmail(user.email)) {
            let data ={
                id: user.uid,
                email: user.email
            }
            axios.post('https://api-bestbrands-mfnz5eq5hq-ey.a.run.app/register', data)
                .then(r => setSent(true))
                .catch(e => setError(true))
        } else {
            setinvalidMail(true)
        }
        setSending(false)
    }


    return (
        <div style={{marginTop: '60px', width:'100%'}}>
            <Typography style={{opacity: 0.8}}>Vuoi provare anche tu 2ndstage?</Typography>
            {sent ?
                <Stack alignItems={"center"} style={{width:'100%'}} sx={{mt:1}}>
                    <div style={{width:'20%'}}>
                        <motion.div
                            initial={{x: 0}}
                            animate={{x: 100}}
                            style={{x: progress}}
                            transition={{duration: 0.8}}
                        />
                        <SuccessCheck progress={progress}/>
                    </div>
                    <Typography style={{marginTop:'0', opacity:0.8}}>Grazie, riceverai via mail i prossimi passi</Typography>
                </Stack>
                :
                (sending && !sent) ?
                    <CircularProgress sx={{color: 'rgba(231,110,81,1)', mt: 1}}/>
                    :
                    <Stack direction={"row"} justifyContent={"center"} spacing={1} sx={{mt: 1, textAlign:'center'}}>
                        <Button variant={"contained"}
                                onClick={inviaMail}
                                style={{
                                    borderRadius: '1.4rem',
                                    background: 'linear-gradient(90deg, rgba(231,110,81,1) 0%, rgba(234,77,149,1) 100%)',
                                    color: "white",

                                    fontSize:'1.2rem'
                                }}
                        >
                            OK
                        </Button>
                    </Stack>
            }
            {error && <div style={{marginTop:'10px', color:'rgba(231,110,81,1)'}}>C'è stato un errore, riprova</div>}
            {invalidMail && <div style={{marginTop:'10px', color:'rgba(231,110,81,1)'}}>L'email inserita non è valida</div>}
        </div>
    )
}

export default ApplyForm
