import React, {useEffect, useState} from "react";
import {firebase} from "../firebase/clientApp";
import Auth from "./auth";
import VoteContent from "../components/VoteContent";
import axios from "axios";
import {useParams} from "react-router-dom";
import {Box} from "@mui/material";

export default function Vote() {
    const {id} =useParams()
    const [user, setUser] = useState(null);

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
                axios.post('https://api.secondstage.app/events/' + id + '/login', {user:user.uid}).then()
                setUser(user);
            })
    }, [])

    return(
        <Box className={'vote'} style={{maxHeight:'100%'}}>
            {!user && <Auth />}
            {user &&
                <VoteContent user={user}/>
            }
        </Box>
    )
}
