import React, {useEffect, useState} from "react";
import Header from "./Header";
import Buttons from "./Buttons";
import Question from "./Question";
import {useParams} from "react-router-dom";
import {firestore} from "../firebase/clientApp";
import ApplyForm from "./ApplyForm";
import {Stack, Typography} from "@mui/material";
import LogoBox from "./LogoBox";
import Agenda from "./Agenda";

export default function VoteContent({user}){
    const {id} = useParams()
    const [idPlaying, setIdPlaying] = useState('')
    const [eventPlaying, setEventPlaying] = useState('')
    const url = 'users/plesh/events/'

    useEffect(() => {
        getIdPlaying()
    }, [])

    function getIdPlaying() {
        firestore.collection(url).doc(id).onSnapshot(s => {
            let e= s.data().elementPlaying
            let g = s.data().playing
            setIdPlaying(e)
            setEventPlaying(g)
        })
    }
    return(
        <Stack direction={"column"} justifyContent={"space-between"} alignContent={"center"} style={{height:'100%'}} sx={{py:2}}>
            <LogoBox/>
            <Typography sx={{mx:2}} component={"div"} variant={"h5"} fontWeight={"bold"} style={{borderBottom: '1px solid white'}}>
                Agenda
            </Typography>
            <Agenda/>
            <Buttons url={url + id} idPlaying={idPlaying} eventPlaying={eventPlaying} user={user}/>
            {/*user.email && <ApplyForm user={user}/>*/}
            <Question idPlaying={idPlaying} url={url + id} user={user}/>
        </Stack>
    )
}
