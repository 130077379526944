import React from "react";
import {Button, Grid, Typography} from "@mui/material";

const SentimentQuestion = ({tipo, sendAnswer, answer}) => {

    return (
        <Grid sx={{position: 'relative'}} container justifyContent={"center"} alignItems={"center"}
              style={{textAlign: 'center', height: '100%', position:'relative'}} spacing={5}>
            <Grid item xs={12} height={'50%'}>
                <Button disabled={answer!==''} onClick={() => sendAnswer('yes')} variant={'sentiment'} risposta={'daccordo'} style={{opacity: answer==='no' && '0.2'}}>
                    <Typography variant={'h5'} fontWeight={'bold'} color={tipo === 'clickTo' ? '#263238' : ''}>Sì</Typography>
                </Button>
            </Grid>
            <Grid item xs={12} height={'50%'}>
                <Button disabled={answer!==''} onClick={() => sendAnswer('no')} variant={'sentiment'} risposta={'nonDaccordo'} style={{opacity: answer==='yes' && '0.2'}}>
                    <Typography variant={'h5'} fontWeight={'bold'}>No</Typography>
                </Button>
            </Grid>
        </Grid>
    )
}

export default SentimentQuestion
