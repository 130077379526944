import React, {useEffect, useState} from "react";
import {firestore} from "../firebase/clientApp";
import QuestionBar from "./QuestionBar";
import QuestionContent from "./QuestionContent";

export default function QuestionDialogContent({url, user,idPlaying,open}) {
    const [elementPlaying, setElementPlaying] = useState({})

    useEffect(()=>{
        idPlaying && getElementPlaying()
    }, [idPlaying])

    function getElementPlaying() {
        firestore.collection(url+'/queue').doc(idPlaying).onSnapshot(s => {
            setElementPlaying({...s.data(), id: s.id})
            return s.data()
        })
    }
    return(
        <div style={{height:'85%', zIndex:1}}>
            <QuestionBar tipo={elementPlaying.tipo} domanda={elementPlaying.domanda}/>
            <QuestionContent elementPlaying={elementPlaying} user={user}/>
        </div>
    )
}
