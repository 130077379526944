import React from "react";
import {Box, Stack, Typography} from "@mui/material";
import intesa from '../assets/images/evento/logo-intesa.png'
import luiss from '../assets/images/evento/logo-luiss.png'
import umana from '../assets/images/evento/logo-umana.png'
import header from '../assets/images/evento/header.png'

const LogoBox = () => {
  return(
      <Box style={{width:'100%', height:'30%'}} sx={{px: 2}}>
          <img style={{width:'100%', maxWidth:'250px', margin:'0.3rem', marginBottom:'1rem'}} src={header}/>
          {/*<Stack direction={"column"} alignItems={"center"} style={{textAlign:'center'}}>
              <img style={{width:'100%', maxWidth:'220px', margin:'0.3rem', marginBottom:'1rem'}} src={conf}/>
              <Stack direction={"row"} style={{width:'100%', padding:'0 1rem'}} justifyContent={"space-between"}>
                  <Box style={{width:'76%'}}>
                      <p style={{fontWeight:"bold", fontFamily:'Barlow', fontSize:'0.8rem', margin:'0', textAlign:'left'}}>Con il sostegno di</p>
                      <Stack direction={"row"} justifyContent={"space-around"} alignItems={"center"} style={{minHeight:'3.4rem'}}>
                          <Box style={{maxWidth:'69%'}}>
                              <img style={{width:'100%', margin:'0.3rem',marginLeft:'0'}} src={intesa}/>
                          </Box>
                          <Box style={{maxWidth:'31%',paddingLeft:'1rem'}}>
                              <img style={{width:'100%', margin:'0.3rem',marginLeft:'0'}} src={umana}/>
                          </Box>
                      </Stack>
                  </Box>
                  <Box>
                      <p style={{fontWeight:"bold", fontFamily:'Barlow', fontSize:'0.8rem', margin:'0'}}>Si ringrazia</p>
                      <img style={{width:'100%', maxWidth:'32px', margin:'0.3rem',}} src={luiss}/>
                  </Box>
              </Stack>


          </Stack>
          <img style={{width: '100%', maxWidth: '500px', marginTop: '30px', margin: '0 auto',}} src={logo}/>*/}
      </Box>
  )
}

export default LogoBox
